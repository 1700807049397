async function createPaymentSelects() {
    const paymentAmountInputWrapper = document.getElementById('payment-amount-wrapper');
    const depositAmountWrapper = document.getElementById('deposit-amount-wrapper');
    const accommodationAmountWrapper = document.getElementById('accommodation-amount-wrapper');
    const paymentAdditionalServiceWrapper = document.getElementById('payment-additional-service-wrapper');

    const accountSelector = document.getElementById('account');
    const spinner = accountSelector.parentNode.querySelector('.field-spinner');

    // Добавляем спиннер, так как загружаем счета
    spinner.classList.remove('hidden');
    let accounts = await fetchAccounts();
    // Далее подготавливаем данные для библиотеки SlimSelect
    accounts = accounts.map(account => {
        return {
            value: account.bnovoAccount + ';' + account.account, // Set the option's value to the account's id
            text: account.account
        }
    });
    // Делает удобные селекты с поиском
    new SlimSelect({
        select: '#account',
        data: accounts,
        settings: {
            placeholderText: 'Счет',
            searchPlaceholder: 'Поиск',
            searchText: 'Не найден',
            searchingText: 'Поиск...',
        }
    });
    // Спиннер не нужен, так как загрузили счета уже
    spinner.classList.add('hidden');

    // Создаем Select для выбора типа платежа
    const paymentTypeSelect = new SlimSelect({
        select: '#payment-type',
        data: [
            {text: 'Оплата за проживание', value: 'accommodation'},
            {text: 'Залог', value: 'deposit'},
            {text: 'Залог + оплата за проживание (если внесли одним платежом)', value: 'deposit+accommodation'},
            {text: 'Доп.услуга', value: 'additional-service'},
        ],
        settings: {
            placeholderText: 'Тип платежа',
            searchPlaceholder: 'Поиск',
            searchText: 'Не найден',
            searchingText: 'Поиск...',
        }
    });

    paymentTypeSelect.events.afterClose = () => {
        const selectedPaymentType = paymentTypeSelect.getSelected()[0];
        if (!selectedPaymentType) {
            return;
        }

        // По умолчанию скрываем все дополнительные поля и раскрываем поле с основной суммой за проживание
        paymentAmountInputWrapper.classList.remove('hidden');

        depositAmountWrapper.classList.add('hidden');
        accommodationAmountWrapper.classList.add('hidden');
        paymentAdditionalServiceWrapper.classList.add('hidden');


        // Для всех типов кроме доп.услуги (у доп.услуги хинт появится после выбора конкретной услуги)
        app.querySelector('.accommodation-amount').value = currentBookingData.hintLivingSum;
        app.querySelector('.payment-amount').value = currentBookingData.hintLivingSum;


        // Если выбран конкретный тип оплаты, показываем соответствующие поля
        if (selectedPaymentType === 'deposit+accommodation') {
            paymentAmountInputWrapper.classList.add('hidden');
            depositAmountWrapper.classList.remove('hidden');
            accommodationAmountWrapper.classList.remove('hidden');
        }
        // Если выбрали доп.услугу, то скрываем поля с суммами
        else if (selectedPaymentType === 'additional-service') {
            paymentAdditionalServiceWrapper.classList.remove('hidden');
            app.querySelector('.payment-amount').value = '';
        } else if (selectedPaymentType === 'deposit') {
            app.querySelector('.payment-amount').value = '';
        }
    }

}

// Создает select для доп.услуг при создании платежа за добавленную уже ранее доп услугу
function createPaymentAdditionalServicesSelectForCurrentBooking(bookingData) {
    const additionalServicesData = [
        {text: 'Выбрать услугу', value: '', display: false, disabled: true},
        ...Object
        .keys(bookingData.additionalServices)
        .map(serviceId =>
            ({text: bookingData.additionalServices[serviceId].service_name, value: serviceId}))];

    const paymentAdditionalServiceSelect = new SlimSelect({
        select: '#payment-additional-service',
        data: additionalServicesData,
        settings: {
            placeholderText: 'Выбрать услугу',
            searchPlaceholder: 'Выбрать услугу',
            searchText: 'Не найден',
            searchingText: 'Поиск...',
            minSelected: 0,
            maxSelected: 1,
        }
    });

    paymentAdditionalServiceSelect.events.afterClose = () => {
        const selectedServiceId = paymentAdditionalServiceSelect.getSelected()[0];
        if (!selectedServiceId) {
            return;
        }

        // Заполняем hint с суммой доп услуги
        const service = bookingData.additionalServices[selectedServiceId];
        app.querySelector('.payment-amount').value = service.sum;
    };
    window.paymentAdditionalServiceSelect = paymentAdditionalServiceSelect;
}

// Загрузка доступных банковских счетов для оплаты
document.addEventListener('DOMContentLoaded', async () => {
    await createPaymentSelects();
});


// Создаем select для выбора доп.услуги из списка услуг чтобы добавить её в бронь
function createAddAdditionalServicesSelectForCurrentBooking(bookingData) {
    // Проставляем актуальный номер брони
    document.getElementById('add-service-text-with-id').innerHTML = 'Будет добавлена в указанном количестве для бронирования ' + bookingData.bookingId;
    const additionalServiceSelect = new SlimSelect({
        select: '#additional-service-select',
        data: [
            {text: 'Выбрать услугу', value: '', display: false, disabled: true},
            ...bookingData.allowedToAddAdditionalService.map(service => ({text: service.name, value: service.id}))
        ],
        settings: {
            placeholderText: 'Выбрать услугу',
            searchPlaceholder: 'Выбрать услугу',
            searchText: 'Не найден',
            searchingText: 'Поиск...',
        }
    });

    const refreshTable = (selectedServiceId) => {
        // Теперь нужно в таблицу с датами, количеством, стоимость добавить столько строк, сколько дней проживания,
        // с учетом того что в верстке уже есть первая tr для примера и её нужно склонировать и заполнить
        const table = document.getElementById('additional-service-table');
        const tbody = table.querySelector('tbody');

        // Проверяем если есть уже у пользователя доп.услуга эта, то автозаполняем её во всех строках
        const arriveDate = new Date(bookingData.arrival);
        const departureDate = new Date(bookingData.departure);
        const numberOfDays = Math.floor((departureDate - arriveDate) / (1000 * 60 * 60 * 24)) + 1;

        const existedServiceId = Object.keys(bookingData.additionalServices).find(serviceId => +serviceId === +selectedServiceId);

        const existedService = existedServiceId ? bookingData.additionalServices[existedServiceId] : null;
        // Удаляем все строки кроме одной первой (она будет как пример)
        [...tbody.querySelectorAll('tr')].forEach((tr, index) => {
            if (index > 0) {
                tr.remove()
            }
        });

        // Далее клонируем и заполняем строки, столько сколько осталось дней до выезда (включая выезд)
        for (let i = -1; i < numberOfDays - 1; i++) {
            const trExample = tbody.querySelector('tr');
            // Клонируем по примеру из верстки
            const tr = trExample.cloneNode(true);
            tbody.appendChild(tr);

            if (tr.querySelector('.row-hint')) {
                tr.querySelector('.row-hint').remove();
            }

            // Удаляем пример (только на -1 итерации)
            if (i === -1) {
                trExample.remove();
            }

            // Вычисляем и заполняем дату
            const date = new Date(+arriveDate);
            date.setDate(date.getDate() + i + 1);

            tr.querySelector('.date').value = getClassicDateFormat(date);
            tr.querySelector('.quantity').value = '';
            tr.querySelector('.sum').value = '';
            // Если есть уже такая услуга на эту дату, то заполняем её в строке
            if (existedService) {
                const existedServiceDateKey = Object.keys(existedService.dates).find(serviceData =>
                    getClassicDateFormat(new Date(serviceData)) === getClassicDateFormat(date));
                if (existedServiceDateKey) {
                    tr.querySelector('.quantity').value = +existedService.dates[existedServiceDateKey].quantity;
                    tr.querySelector('.sum').value = +existedService.dates[existedServiceDateKey].price;
                    // Добавляем подсказку что услуга уже добавлена в биново
                    tr.insertAdjacentHTML('beforeend', `
                    <td class="row-hint">
                        <div class="text-sm text-gray-500">Услуга добавлена</div>
                    </td>
                    `);
                }
            }
        }
    }
    additionalServiceSelect.events.afterClose = () => {
        const selectedServiceId = additionalServiceSelect.getSelected()[0];
        if (!selectedServiceId) {
            return;
        }
        refreshTable(selectedServiceId);
    };
    window.additionalServiceSelect = additionalServiceSelect;
    refreshTable(); // Делаем первое обновление таблицы

    // Открываем модальное окно
    const modal = document.getElementById('add-service-modal');
    modal.classList.remove('hidden');
}
// Создаем select для выбора отеля из списка отелей
function createHotelSelect() {
    // Достаем из конфига список отелей и формируем select
    const selectValues = Object.keys(config.hotels).map(value => {
        return {text: config.hotels[value].text, value}
    });
    // Создаем Select для выбора типа платежа
    window.hotelsSelect = new SlimSelect({
        select: '#hotel',
        data: selectValues,
        settings: {
            placeholderText: 'Отель',
            searchPlaceholder: 'Поиск',
            searchText: 'Не найден',
            searchingText: 'Поиск...',
            closeOnSelect: true,
        }
    });
    window.hotelsSelect.events.afterClose = window.selectBookingListener;
}

document.addEventListener('DOMContentLoaded', () => {
    createHotelSelect();
});

document.addEventListener('bookingSuccessfullyLoaded', (event) => {
    const bookingData = event.detail;
    createPaymentAdditionalServicesSelectForCurrentBooking(bookingData);
});

/* Возвраты */

// Select для отображения статьи
document.addEventListener('DOMContentLoaded', function() {
    const selectElement = document.querySelector('#refund-article');

    // Определить опции для добавления
    const options = ["Возвраты гостям (проживание, доп.услуги)", "Залог"];

    // Создать и добавить опции
    options.forEach(optionText => {
        const optionElement = document.createElement("option");
        optionElement.text = optionText;
        optionElement.value = optionText;
        selectElement.appendChild(optionElement);
    });

    // Инициализировать SlimSelect
    window.refundArticleSelect = new SlimSelect({
      select: '#refund-article'
    });
});


// Отображение способа оплаты гостем для Возвратов
document.addEventListener('DOMContentLoaded', function () {
    const refundAccount = document.querySelector('#refund-account');
    // Определить опции для добавления
    const options = ["Безналичная оплата", "Наличными", "Перевод на карту", "Онлайн оплата"].map(optionText => {
        return {
            value: optionText,
            text: optionText
        }
    });

    // Инициализировать SlimSelect на элементе select
    const refundPaymentWaySelect = new SlimSelect({
        select: '#refund-payment-way',
        // Добавляем пустое значение чтобы автоматически не было выбранного
        data: [{text: 'Выбрать способ оплаты', value: '', display: false, disabled: true}, ...options],
        settings: {
            placeholderText: 'Способ оплаты гостем',
            searchPlaceholder: 'Поиск',
            searchText: 'Не найден',
            searchingText: 'Поиск...',
            closeOnSelect: true,
        }
    });

    window.refundPaymentWaySelect = refundPaymentWaySelect;

    refundPaymentWaySelect.events.afterClose = () => {
        const selected = refundPaymentWaySelect.getSelected()[0];
        refundAccount.parentNode.classList.add('hidden');
        // Ставим значение оплата по ссылке и скрываем выбор
        refundAccountSelect.setSelected("Оплата по ссылке");
        refundAccountSelect.events.afterClose(); // Эммитируем событие
        refundAccountSelect.enable();

        if (selected === 'Перевод на карту') {
            refundAccount.parentNode.classList.remove('hidden');
            // Ставим значение 'Внести данные' и скрываем выбор
            refundAccountSelect.setSelected("Внести данные");
            refundAccountSelect.events.afterClose(); // Эммитируем событие
            refundAccountSelect.disable();
        } else if (selected === 'Онлайн-оплата') {
          // Ставим значение оплата по ссылке и скрываем выбор
          refundAccountSelect.setSelected("Оплата по ссылке");
          refundAccountSelect.events.afterClose(); // Эммитируем событие
          refundAccountSelect.disable();
        }
    };
});

// Отображение селекта для поля: счет для возврата
document.addEventListener('DOMContentLoaded', function () {
    const refundDetails = document.querySelector('#refund-details');
    const selectElement = document.querySelector('#refund-account');

    // Определить опции
    const options = ["Оплата по ссылке", "Внести данные"];

    // Создать и добавить опции
    options.forEach(optionText => {
        const optionElement = document.createElement("option");
        optionElement.text = optionText;
        optionElement.value = optionText;
        selectElement.appendChild(optionElement);
    });

    // Инициализировать SlimSelect
    const refundAccountSelect = new SlimSelect({
        select: '#refund-account'
    });
    window.refundAccountSelect = refundAccountSelect;

    // Событие выбора: если Внести Данные - то доп поля с указанием телефона, ФИО и тд
    refundAccountSelect.events.afterClose = () => {
        const selected = refundAccountSelect.getSelected()[0];
        if (selected === 'Внести данные') {
            refundDetails.classList.remove('hidden');
        } else {
            refundDetails.classList.add('hidden');
        }
    };

});

// Отображение селекта для поля Источник оплаты:
document.addEventListener('DOMContentLoaded', async function () {
    const accountSelector = document.getElementById('account-2');
    const spinner = accountSelector.parentNode.querySelector('.field-spinner');

    // Добавляем спиннер, так как загружаем счета
    spinner.classList.remove('hidden');
    let accounts = await fetchAccounts();

    // Prepare data for SlimSelect library
    accounts = accounts.map(account => {
        return {
            value: account.bnovoAccount + ';' + account.account, // Set the option's value to the account's id
            text: account.account
        }
    });

    // Initialize SlimSelect with search functionality
    new SlimSelect({
        select: '#account-2',
        data: accounts,
        settings: {
            placeholderText: 'Источник оплаты',
            searchPlaceholder: 'Поиск',
            searchText: 'Не найден',
            searchingText: 'Поиск...',
        }
    });

    // Accounts have been loaded, so spinner is no longer needed
    spinner.classList.add('hidden');
});

/* ----- */

